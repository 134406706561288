import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '@components/layout';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '@components/seo';
import * as styles from './klar.module.css';
import AppleStoreBadge from '@src/images/appstore.png';
import GooglePlayBadge from '@src/images/google_play2.png';
import { useMedia } from '@src/lib/hooks';
import { formatMoneyText } from '@src/lib/utils';
import Hero from '../../components/hero';
import Contact from '../../components/contact';
import { AppDownloadUrl } from '../../pageConstants';
import '@src/sentry'; // Enable Sentry monitoring for errors

const MobileSplash = ({ iconSrc }) => (
  <div
    style={{
      margin: '0 0 0 100px',
    }}
  >
    <img
      height={400}
      style={{
        position: 'relative',
      }}
      src={iconSrc}
      alt="Splash"
    ></img>
  </div>
);
MobileSplash.propTypes = {
  iconSrc: PropTypes.string.isRequired,
};

const AppBadges = ({ className, style }) => {
  return (
    <div className={className} style={style}>
      <a href={AppDownloadUrl} rel="noopener noreferrer" target="_blank">
        <img src={AppleStoreBadge} height={50} alt="Splash" />
      </a>
      <a
        href={AppDownloadUrl}
        rel="noopener noreferrer"
        target="_blank"
        style={{ marginLeft: 20 }}
      >
        <img src={GooglePlayBadge} height={50} alt="Splash" />
      </a>
    </div>
  );
};

AppBadges.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default function ApplicationCompletePage({ data }) {
  const { amount } = useSelector(
    state => state.accountCreditApplication.accountCreditDetails.creditLimit,
  );
  const isMobile = useMedia(['(max-width: 896px)'], [true], false);
  const heroImage = data.contentfulPage.heroImage;
  const pageContent =
    data.contentfulPage.pageContent[0].bodyText.childMarkdownRemark.html;
  const contactContent =
    data.contentfulPage.pageContent[2].bodyText.childMarkdownRemark.html;
  const headLine = data.contentfulPage.headLine;
  const headerTitle = data.contentfulPage.pageHeader;

  const pageContentWithCreditLimitAmount = pageContent.replace(
    '10 000 kr',
    formatMoneyText(amount),
  );

  const headerContent = (
    <div style={{ flexDirection: 'column' }} className="text-small">
      <div
        dangerouslySetInnerHTML={{ __html: pageContentWithCreditLimitAmount }}
      />
      <AppBadges />
      <div dangerouslySetInnerHTML={{ __html: contactContent }} />
    </div>
  );

  return (
    <Layout path="/kredit" newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        headerType="largeHeader"
        title={headerTitle}
        headerContent={headerContent}
        alignItems="flex-start"
        heroImage={heroImage}
        headerClassName={isMobile ? '' : styles.header}
      />
      <div className="container narrow">{headerContent}</div>
      <Contact />
    </Layout>
  );
}

ApplicationCompletePage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query AccountCreditCompletePageQuery {
    contentfulPage(pageUrl: { eq: "/kredit/klar" }) {
      headLine
      pageHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFeature {
          icon {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
